import { HTTP } from '@/api/http'

export async function getLocations () {
  const { data } = await HTTP.get('/v1/locations')
  return data
}

export async function getLocation (id) {
  const { data } = await HTTP.get(`/v1/locations/${id}`)
  return data
}

export async function createOptimalLocations (postalCodes, commutingRegion) {
  const { data }  = await HTTP.post(`/v1/optimal_locations`, {
    postal_codes: postalCodes,
    commuting_region: commutingRegion
  })

  return data
}

export async function getResults (id, token) {
  const { data } = await HTTP.get(`/v1/optimal_locations/${id}?token=${token}`)
  return data
}

export async function sendLink (id, email) {
  await HTTP.post(`/v1/optimal_locations/${id}/links`, {
    email: email
  })
}

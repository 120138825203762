<template>
  <v-container>
    <h1>Welcome to NoofLab platform!</h1>
    <v-text-field
      label="Search"
      prepend-icon="mdi-home-search"
      solo
      v-on:keyup.enter="search"
      v-model="postalCodes"
    >
    </v-text-field>
  </v-container>
</template>

<script>
export default {
  name: 'SearchPage',
  data: function () {
    return {
      postalCodes: ''
    }
  },
  methods: {
    search () {
      console.log('search')
      const codes = this.postalCodes.split(',')

      this.$store.dispatch('createOptimalLocations', { postalCodes: codes, commutingRegion: 'capital_region' }).then((results) => {
        console.log('found')

        this.$router.push({ name: 'ResultsPage', params: { id: results.id } })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <v-container>
    <div v-if="location">
      <h1>{{ location.name }}</h1>

      <h2>Muita yrityksiä tässä osoitteessa</h2>
      <div v-if="(location.neighbours.length === 0)">
        <p>Tiedossamme ei ole tähän osoitteeseen rekisteröityjä yrityksiä.</p>
      </div>

      <ul class="company-list">
        <li v-for="neighbour in orderBy(location.neighbours, ['name'])" v-bind:key="neighbour.id">
          {{ neighbour.name }}
        </li>
      </ul>
    </div>
  </v-container>
</template>

<script>
import orderBy from 'lodash/orderBy'

import * as api from '@/api'

export default {
  name: 'LocationPage',
  data: function () {
    return {
      location: null
    }
  },
  methods: {
    orderBy
  },
  mounted () {
    console.log('hai')
    api.getLocation(this.$route.params.id).then((location) => {
      this.location = location
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.company-list {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0.25em 0;
  }
}
</style>

import { createOptimalLocations, getResults } from '@/api'

export default {
  state: {
    result: null
  },
  mutations: {
    setResult (state, result) {
      state.result = result
    },

    clearResult (state) {
      state.result = null
    }
  },
  actions: {
    createOptimalLocations (context, payload) {
      return new Promise(resolve => {
        createOptimalLocations(payload.postalCodes, payload.commutingRegion).then(result => {
          context.commit('setResult', result)

          resolve(result)
        })
      })
    },

    getOptimalLocations (context, payload) {
      return new Promise(resolve => {
        getResults(payload.id, payload.token).then(result => {
          context.commit('setResult', result)
          resolve(result)
        })
      })
    }
  }
}

<template>
  <v-app>
    <v-main>
      <router-view></router-view>

      <site-footer />
    </v-main>
  </v-app>
</template>

<script>
import SiteFooter from '@/components/SiteFooter'

export default {
  name: 'App',
  components: { SiteFooter },
  data: () => ({
  }),
};
</script>

<style lang="scss">
#app {
  background-color: #303563;
  background-image: url(./assets/map_white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: silkamedium;
}
.container {
  background-color: #ffffff;
}
</style>

<template>
  <div class="site-footer-container">
    <v-container>
      <div class="site-footer">
        <v-row>
          <v-col>
            <h3>Yhteystiedot</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p>
              Jyrki Vaittinen, Chief <br/>
              +358 40 703 1662 <br/>
              jyrki.vaittinen@kaikkitoimitilat.fi <br/>
            </p>
          </v-col>
          <v-col>
            <a href="https://www.instagram.com/nooflab/">
              Instagram
            </a>
          </v-col>
          <v-col>
            <div class="site-tagline">
              <LogoWhite />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import LogoWhite from '@/components/LogoWhite'

export default {
  name: 'SiteFooter',
  components: { LogoWhite },
  data: () => ({
  }),
};
</script>

<style lang="scss" scoped>
.site-footer {
  background-color: #303563;
  color: #ffffff;
  padding: 4rem;

  .site-tagline {
    text-align: center;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
  a:visited {
    color: #ffffff;
  }
}

.site-footer-container {
  .container {
    padding: 0;
  }
}
</style>

<template>
  <svg width="207" height="20" viewBox="0 0 207 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.8814 19.6592L7.85128 10.983L12.5354 5.87156H8.2505L3.61957 11.1664H3.45988V0H0V19.6592H3.45988V15.7536L5.48259 13.5518L8.99571 19.6592H12.8814Z" fill="#303563"/>
  <path d="M19.2186 20C21.7736 20 23.0245 18.4797 23.4237 17.903H23.61V19.6592H27.0166V5.87156H23.5568V7.654H23.3705C22.8914 6.94627 21.5341 5.5308 19.1121 5.5308C15.2796 5.5308 12.6182 8.519 12.6182 12.844C12.6182 17.1166 15.3063 20 19.2186 20ZM19.8839 16.9332C17.6483 16.9332 16.1313 15.2818 16.1313 12.7916C16.1313 10.3539 17.6483 8.62385 19.8573 8.62385C22.0397 8.62385 23.6366 10.1966 23.6366 12.7916C23.6366 15.0983 22.1994 16.9332 19.8839 16.9332Z" fill="#303563"/>
  <path d="M31.4779 4.0367C32.7288 4.0367 33.6071 3.27654 33.6071 2.04456C33.6071 0.865008 32.7288 0.0786375 31.4779 0.0786375C30.227 0.0786375 29.3487 0.865008 29.3487 2.04456C29.3487 3.27654 30.227 4.0367 31.4779 4.0367ZM29.748 19.6592H33.2078V5.87156H29.748V19.6592Z" fill="#303563"/>
  <path d="M48.8224 19.6592L43.7923 10.983L48.4765 5.87156H44.1915L39.5606 11.1664H39.4009V0H35.941V19.6592H39.4009V15.7536L41.4236 13.5518L44.9367 19.6592H48.8224Z" fill="#303563"/>
  <path d="M63.2806 19.6592L58.2504 10.983L62.9346 5.87156H58.6497L54.0187 11.1664H53.859V0H50.3992V19.6592H53.859V15.7536L55.8817 13.5518L59.3949 19.6592H63.2806Z" fill="#303563"/>
  <path d="M66.5872 4.0367C67.8381 4.0367 68.7164 3.27654 68.7164 2.04456C68.7164 0.865008 67.8381 0.0786375 66.5872 0.0786375C65.3363 0.0786375 64.4581 0.865008 64.4581 2.04456C64.4581 3.27654 65.3363 4.0367 66.5872 4.0367ZM64.8573 19.6592H68.3172V5.87156H64.8573V19.6592Z" fill="#303563"/>
  <path d="M77.1185 19.6592H79.7001V16.8283H77.8371C76.5063 16.8283 75.9208 16.0944 75.9208 14.941V8.59764H79.7799V5.87156H75.9208V2.07077H72.6738V4.84928C72.6738 5.50459 72.2746 5.89777 71.6625 5.89777H70.0922V8.59764H72.4609V15.0721C72.4609 18.0341 74.2175 19.6592 77.1185 19.6592Z" fill="#303563"/>
  <path d="M88.4955 20C92.8603 20 95.9476 16.8545 95.9476 12.7654C95.9476 8.67628 92.8869 5.5308 88.4955 5.5308C84.0775 5.5308 81.0435 8.67628 81.0435 12.7654C81.0435 16.8807 84.1041 20 88.4955 20ZM88.4955 16.9069C86.2067 16.9069 84.5566 15.2031 84.5566 12.7654C84.5566 10.3801 86.1801 8.62385 88.4955 8.62385C90.7844 8.62385 92.4345 10.3801 92.4345 12.7654C92.4345 15.1769 90.7844 16.9069 88.4955 16.9069Z" fill="#303563"/>
  <path d="M99.9292 4.0367C101.18 4.0367 102.058 3.27654 102.058 2.04456C102.058 0.865008 101.18 0.0786375 99.9292 0.0786375C98.6783 0.0786375 97.8 0.865008 97.8 2.04456C97.8 3.27654 98.6783 4.0367 99.9292 4.0367ZM98.1992 19.6592H101.659V5.87156H98.1992V19.6592Z" fill="#303563"/>
  <path d="M104.392 19.6592H107.852V11.7431C107.852 9.98689 108.997 8.7287 110.54 8.7287C112.137 8.7287 113.148 9.80341 113.148 11.481V19.6592H116.608V11.7693C116.608 9.90826 117.753 8.7287 119.323 8.7287C120.973 8.7287 121.851 9.80341 121.851 11.6907V19.6592H125.311V10.5111C125.311 7.60157 123.289 5.5308 120.201 5.5308C118.392 5.5308 117.008 6.18611 116.049 7.62779H115.837C115.038 6.34338 113.601 5.5308 111.685 5.5308C109.768 5.5308 108.491 6.50066 107.879 7.54915H107.693V5.87156H104.392V19.6592Z" fill="#303563"/>
  <path d="M129.781 4.0367C131.032 4.0367 131.91 3.27654 131.91 2.04456C131.91 0.865008 131.032 0.0786375 129.781 0.0786375C128.53 0.0786375 127.652 0.865008 127.652 2.04456C127.652 3.27654 128.53 4.0367 129.781 4.0367ZM128.051 19.6592H131.511V5.87156H128.051V19.6592Z" fill="#303563"/>
  <path d="M140.312 19.6592H142.894V16.8283H141.031C139.7 16.8283 139.115 16.0944 139.115 14.941V8.59764H142.974V5.87156H139.115V2.07077H135.868V4.84928C135.868 5.50459 135.469 5.89777 134.856 5.89777H133.286V8.59764H135.655V15.0721C135.655 18.0341 137.411 19.6592 140.312 19.6592Z" fill="#303563"/>
  <path d="M146.846 4.0367C148.096 4.0367 148.975 3.27654 148.975 2.04456C148.975 0.865008 148.096 0.0786375 146.846 0.0786375C145.595 0.0786375 144.716 0.865008 144.716 2.04456C144.716 3.27654 145.595 4.0367 146.846 4.0367ZM145.116 19.6592H148.576V5.87156H145.116V19.6592Z" fill="#303563"/>
  <path d="M155.141 19.6592H156.445V16.671H155.886C155.168 16.671 154.769 16.2516 154.769 15.4653V0H151.309V15.7798C151.309 18.2176 152.693 19.6592 155.141 19.6592Z" fill="#303563"/>
  <path d="M164.186 20C166.741 20 167.991 18.4797 168.391 17.903H168.577V19.6592H171.984V5.87156H168.524V7.654H168.337C167.858 6.94627 166.501 5.5308 164.079 5.5308C160.247 5.5308 157.585 8.519 157.585 12.844C157.585 17.1166 160.273 20 164.186 20ZM164.851 16.9332C162.615 16.9332 161.098 15.2818 161.098 12.7916C161.098 10.3539 162.615 8.62385 164.824 8.62385C167.007 8.62385 168.604 10.1966 168.604 12.7916C168.604 15.0983 167.166 16.9332 164.851 16.9332Z" fill="#303563"/>
  <path d="M180.783 19.6592H183.365V16.8283H181.502C180.171 16.8283 179.585 16.0944 179.585 14.941V8.59764H183.444V5.87156H179.585V2.07077H176.338V4.84928C176.338 5.50459 175.939 5.89777 175.327 5.89777H173.757V8.59764H176.125V15.0721C176.125 18.0341 177.882 19.6592 180.783 19.6592Z" fill="#303563"/>
  <path d="M186.977 20C188.148 20 189 19.1874 189 18.0603C189 16.9594 188.148 16.1206 186.977 16.1206C185.859 16.1206 184.954 16.9594 184.954 18.0603C184.954 19.1874 185.859 20 186.977 20Z" fill="#303563"/>
  <path d="M192.976 19.6592H196.436V8.59764H200.109V5.87156H196.41V4.87549C196.41 3.72215 197.048 2.83093 198.539 2.83093H200.003V0H198.193C195.159 0 193.056 1.80865 193.056 4.77064V5.87156H190.581V8.59764H192.976V19.6592Z" fill="#303563"/>
  <path d="M204.121 4.0367C205.372 4.0367 206.25 3.27654 206.25 2.04456C206.25 0.865008 205.372 0.0786375 204.121 0.0786375C202.87 0.0786375 201.992 0.865008 201.992 2.04456C201.992 3.27654 202.87 4.0367 204.121 4.0367ZM202.391 19.6592H205.851V5.87156H202.391V19.6592Z" fill="#303563"/>
  </svg>
</template>


<script>
export default {
   name: 'LogoBlue',
}
</script>

<template>
  <v-container>
    <h1>Kohteet</h1>
    <div v-if="locations">
      <v-text-field
      label="Search"
      prepend-icon="mdi-home-search"
      solo
      v-on:keyup.enter="search"
      v-model="searchTerm"
      >
      </v-text-field>

      <ul class="location-list">
        <li v-for="location in filteredLocations" v-bind:key="location.id">
          <router-link :to="{ name: 'LocationPage', params: { id: location.id, slug: slugify(location.name) }}">
            {{ location.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </v-container>
</template>

<script>
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import slugify from 'slugify'

import * as api from '@/api'

export default {
  name: 'LocationsPage',
  data: function () {
    return {
      searchTerm: null,
      locations: null
    }
  },
  methods: {
    orderBy,
    slugify
  },
  computed: {
    filteredLocations () {
      return orderBy(
        filter(this.locations, (o) => {
          if (this.searchTerm?.length > 0) {
            return o.name.toLowerCase().includes(this.searchTerm)
          } else {
            return true
          }
        }),
        ['name']
      )
    }
  },
  mounted () {
    api.getLocations().then((locations) => {
      this.locations = locations
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.location-list {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0.25em 0;
  }
}
</style>

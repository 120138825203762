export function buildFriendlyTime (seconds) {
  let d = Number(seconds);
  var h = Math.floor(d / 3600)
  var m = Math.floor(d % 3600 / 60)
  var s = Math.floor(d % 3600 % 60)

  return { hours: h, minutes: m, seconds: s }
}

export function friendlyTime (seconds, pattern = 'hms') {
  let time = buildFriendlyTime(seconds)

  // TODO: localisation
  var hDisplay = time.hours > 0 ? time.hours + (time.hours == 1 ? " tunti, " : " tuntia, ") : ""
  var mDisplay = time.minutes > 0 ? time.minutes + (time.minutes == 1 ? " minuutti, " : " minuuttia, ") : ""
  var sDisplay = time.seconds > 0 ? time.seconds + (time.seconds == 1 ? " sekuntti" : " sekunttia, ") : ""

  return pattern
    .replace('h', hDisplay)
    .replace('m', mDisplay)
    .replace('s', sDisplay)
    .trim()
    .slice(0, -1)
}

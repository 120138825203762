import Vue from 'vue'
import Vuex from 'vuex'

import optimalLocationStore from '@/store/modules/optimalLocation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    optimalLocation: optimalLocationStore
  }
})

import Vue from "vue"
import Router from "vue-router"

import SearchPage from '@/components/SearchPage'
import ResultsPage from '@/components/ResultsPage'
import LocationPage from '@/components/LocationPage'
import LocationsPage from '@/components/LocationsPage'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/search",
      name: "SearchPage",
      component: SearchPage
    },
    {
      path: "/results/:id",
      name: "ResultsPage",
      component: ResultsPage
    },
    {
      path: "/locations/:id/:slug?",
      name: "LocationPage",
      component: LocationPage
    },
    {
      path: "/locations",
      name: "LocationsPage",
      component: LocationsPage
    }
  ]
})

<template>
  <svg width="280" height="55" viewBox="0 0 280 55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.4875 26.54L10.6587 14.827L17.0178 7.92661H11.2007L4.91385 15.0747H4.69706V0H0V26.54H4.69706V21.2674L7.44303 18.2949L12.2124 26.54H17.4875Z" fill="white"/>
  <path d="M26.0907 27C29.5593 27 31.2575 24.9476 31.7994 24.1691H32.0523V26.54H36.6771V7.92661H31.9801V10.3329H31.7272C31.0768 9.37746 29.2341 7.46658 25.9462 7.46658C20.7433 7.46658 17.1301 11.5007 17.1301 17.3395C17.1301 23.1075 20.7794 27 26.0907 27ZM26.994 22.8598C23.9589 22.8598 21.8995 20.6304 21.8995 17.2687C21.8995 13.9777 23.9589 11.6422 26.9578 11.6422C29.9206 11.6422 32.0885 13.7654 32.0885 17.2687C32.0885 20.3827 30.1374 22.8598 26.994 22.8598Z" fill="white"/>
  <path d="M42.7336 5.44954C44.4318 5.44954 45.6241 4.42333 45.6241 2.76016C45.6241 1.16776 44.4318 0.106161 42.7336 0.106161C41.0355 0.106161 39.8431 1.16776 39.8431 2.76016C39.8431 4.42333 41.0355 5.44954 42.7336 5.44954ZM40.3851 26.54H45.0822V7.92661H40.3851V26.54Z" fill="white"/>
  <path d="M66.2802 26.54L59.4514 14.827L65.8105 7.92661H59.9933L53.7065 15.0747H53.4897V0H48.7927V26.54H53.4897V21.2674L56.2357 18.2949L61.005 26.54H66.2802Z" fill="white"/>
  <path d="M85.9082 26.54L79.0794 14.827L85.4385 7.92661H79.6213L73.3345 15.0747H73.1177V0H68.4207V26.54H73.1177V21.2674L75.8637 18.2949L80.633 26.54H85.9082Z" fill="white"/>
  <path d="M90.3972 5.44954C92.0954 5.44954 93.2877 4.42333 93.2877 2.76016C93.2877 1.16776 92.0954 0.106161 90.3972 0.106161C88.699 0.106161 87.5067 1.16776 87.5067 2.76016C87.5067 4.42333 88.699 5.44954 90.3972 5.44954ZM88.0487 26.54H92.7457V7.92661H88.0487V26.54Z" fill="white"/>
  <path d="M104.694 26.54H108.199V22.7182H105.67C103.863 22.7182 103.068 21.7274 103.068 20.1704V11.6068H108.307V7.92661H103.068V2.79555H98.6602V6.54653C98.6602 7.43119 98.1183 7.96199 97.2872 7.96199H95.1555V11.6068H98.3712V20.3473C98.3712 24.346 100.756 26.54 104.694 26.54Z" fill="white"/>
  <path d="M120.139 27C126.065 27 130.256 22.7536 130.256 17.2333C130.256 11.713 126.101 7.46658 120.139 7.46658C114.142 7.46658 110.023 11.713 110.023 17.2333C110.023 22.789 114.178 27 120.139 27ZM120.139 22.8244C117.032 22.8244 114.792 20.5243 114.792 17.2333C114.792 14.0131 116.996 11.6422 120.139 11.6422C123.247 11.6422 125.487 14.0131 125.487 17.2333C125.487 20.4889 123.247 22.8244 120.139 22.8244Z" fill="white"/>
  <path d="M135.661 5.44954C137.36 5.44954 138.552 4.42333 138.552 2.76016C138.552 1.16776 137.36 0.106161 135.661 0.106161C133.963 0.106161 132.771 1.16776 132.771 2.76016C132.771 4.42333 133.963 5.44954 135.661 5.44954ZM133.313 26.54H138.01V7.92661H133.313V26.54Z" fill="white"/>
  <path d="M141.72 26.54H146.418V15.8532C146.418 13.4823 147.971 11.7837 150.067 11.7837C152.235 11.7837 153.608 13.2346 153.608 15.4993V26.54H158.305V15.8886C158.305 13.3761 159.858 11.7837 161.99 11.7837C164.23 11.7837 165.423 13.2346 165.423 15.7824V26.54H170.12V14.19C170.12 10.2621 167.374 7.46658 163.182 7.46658C160.725 7.46658 158.847 8.35125 157.546 10.2975H157.257C156.173 8.56357 154.222 7.46658 151.62 7.46658C149.019 7.46658 147.285 8.77589 146.454 10.1914H146.201V7.92661H141.72V26.54Z" fill="white"/>
  <path d="M176.188 5.44954C177.886 5.44954 179.078 4.42333 179.078 2.76016C179.078 1.16776 177.886 0.106161 176.188 0.106161C174.49 0.106161 173.297 1.16776 173.297 2.76016C173.297 4.42333 174.49 5.44954 176.188 5.44954ZM173.839 26.54H178.536V7.92661H173.839V26.54Z" fill="white"/>
  <path d="M190.485 26.54H193.989V22.7182H191.46C189.654 22.7182 188.859 21.7274 188.859 20.1704V11.6068H194.098V7.92661H188.859V2.79555H184.451V6.54653C184.451 7.43119 183.909 7.96199 183.078 7.96199H180.946V11.6068H184.162V20.3473C184.162 24.346 186.546 26.54 190.485 26.54Z" fill="white"/>
  <path d="M199.354 5.44954C201.052 5.44954 202.244 4.42333 202.244 2.76016C202.244 1.16776 201.052 0.106161 199.354 0.106161C197.656 0.106161 196.463 1.16776 196.463 2.76016C196.463 4.42333 197.656 5.44954 199.354 5.44954ZM197.005 26.54H201.702V7.92661H197.005V26.54Z" fill="white"/>
  <path d="M210.616 26.54H212.386V22.5059H211.628C210.652 22.5059 210.11 21.9397 210.11 20.8781V0H205.413V21.3028C205.413 24.5937 207.292 26.54 210.616 26.54Z" fill="white"/>
  <path d="M222.894 27C226.363 27 228.061 24.9476 228.603 24.1691H228.856V26.54H233.481V7.92661H228.784V10.3329H228.531C227.88 9.37746 226.038 7.46658 222.75 7.46658C217.547 7.46658 213.934 11.5007 213.934 17.3395C213.934 23.1075 217.583 27 222.894 27ZM223.798 22.8598C220.763 22.8598 218.703 20.6304 218.703 17.2687C218.703 13.9777 220.763 11.6422 223.761 11.6422C226.724 11.6422 228.892 13.7654 228.892 17.2687C228.892 20.3827 226.941 22.8598 223.798 22.8598Z" fill="white"/>
  <path d="M245.427 26.54H248.931V22.7182H246.402C244.596 22.7182 243.801 21.7274 243.801 20.1704V11.6068H249.04V7.92661H243.801V2.79555H239.393V6.54653C239.393 7.43119 238.851 7.96199 238.02 7.96199H235.888V11.6068H239.104V20.3473C239.104 24.346 241.488 26.54 245.427 26.54Z" fill="white"/>
  <path d="M253.836 27C255.425 27 256.582 25.903 256.582 24.3814C256.582 22.8952 255.425 21.7628 253.836 21.7628C252.318 21.7628 251.09 22.8952 251.09 24.3814C251.09 25.903 252.318 27 253.836 27Z" fill="white"/>
  <path d="M261.98 26.54H266.677V11.6068H271.663V7.92661H266.641V6.58192C266.641 5.0249 267.508 3.82176 269.532 3.82176H271.519V0H269.062C264.943 0 262.088 2.44168 262.088 6.44037V7.92661H258.728V11.6068H261.98V26.54Z" fill="white"/>
  <path d="M277.109 5.44954C278.808 5.44954 280 4.42333 280 2.76016C280 1.16776 278.808 0.106161 277.109 0.106161C275.411 0.106161 274.219 1.16776 274.219 2.76016C274.219 4.42333 275.411 5.44954 277.109 5.44954ZM274.761 26.54H279.458V7.92661H274.761V26.54Z" fill="white"/>
  <path d="M74.611 51.4796H76.7679V42.5561H80.06V40.7653H71.3513V42.5561H74.611V51.4796Z" fill="white"/>
  <path d="M83.0731 42.3571C83.8354 42.3571 84.3705 41.9133 84.3705 41.1939C84.3705 40.5051 83.8354 40.0459 83.0731 40.0459C82.3109 40.0459 81.7758 40.5051 81.7758 41.1939C81.7758 41.9133 82.3109 42.3571 83.0731 42.3571ZM82.019 51.4796H84.1273V43.4286H82.019V51.4796Z" fill="white"/>
  <path d="M88.9389 51.4796H89.7335V49.7347H89.393C88.9551 49.7347 88.7118 49.4898 88.7118 49.0306V40H86.6036V49.2143C86.6036 50.6378 87.4469 51.4796 88.9389 51.4796Z" fill="white"/>
  <path d="M95.2609 51.6786C96.8177 51.6786 97.5799 50.7908 97.8232 50.4541H97.9367V51.4796H100.013V43.4286H97.9043V44.4694H97.7908C97.4989 44.0561 96.6718 43.2296 95.196 43.2296C92.8607 43.2296 91.2389 44.9745 91.2389 47.5C91.2389 49.9949 92.8769 51.6786 95.2609 51.6786ZM95.6663 49.8878C94.304 49.8878 93.3796 48.9235 93.3796 47.4694C93.3796 46.0459 94.304 45.0357 95.6501 45.0357C96.9799 45.0357 97.9529 45.9541 97.9529 47.4694C97.9529 48.8163 97.0772 49.8878 95.6663 49.8878Z" fill="white"/>
  <path d="M102.488 51.4796H104.596V46.8724C104.596 45.8622 105.326 45.0969 106.347 45.0969C107.32 45.0969 107.985 45.7551 107.985 46.7347V51.4796H110.094V46.2143C110.094 44.5 108.926 43.2296 107.012 43.2296C105.796 43.2296 104.953 43.8112 104.612 44.4082H104.499V43.4286H102.488V51.4796Z" fill="white"/>
  <path d="M112.568 51.4796H114.676V46.8724C114.676 45.8622 115.406 45.0969 116.428 45.0969C117.401 45.0969 118.065 45.7551 118.065 46.7347V51.4796H120.174V46.2143C120.174 44.5 119.006 43.2296 117.092 43.2296C115.876 43.2296 115.033 43.8112 114.692 44.4082H114.579V43.4286H112.568V51.4796Z" fill="white"/>
  <path d="M126.41 51.6786C128.616 51.6786 130.14 50.6071 130.562 48.9694H128.6C128.308 49.6276 127.513 50.0867 126.475 50.0867C125.113 50.0867 124.221 49.2602 124.156 48.0357H130.643V47.4082C130.643 45.1429 129.151 43.2296 126.41 43.2296C123.913 43.2296 122.113 44.9592 122.113 47.5C122.113 49.9031 123.897 51.6786 126.41 51.6786ZM124.189 46.6276C124.318 45.6173 125.226 44.8214 126.41 44.8214C127.643 44.8214 128.535 45.5408 128.632 46.6276H124.189Z" fill="white"/>
  <path d="M137.455 55H139.563V50.4388H139.677C139.953 50.8827 140.763 51.6786 142.272 51.6786C144.607 51.6786 146.229 49.9337 146.229 47.4235C146.229 44.9133 144.591 43.2296 142.207 43.2296C140.65 43.2296 139.871 44.1173 139.644 44.4541H139.531V43.4286H137.455V55ZM141.801 49.8724C140.488 49.8724 139.515 48.9541 139.515 47.4541C139.515 46.0918 140.39 45.0357 141.785 45.0357C143.164 45.0357 144.088 45.9847 144.088 47.4541C144.088 48.8776 143.164 49.8724 141.801 49.8724Z" fill="white"/>
  <path d="M151.909 51.6786C153.466 51.6786 154.228 50.7908 154.471 50.4541H154.585V51.4796H156.66V43.4286H154.552V44.4694H154.439C154.147 44.0561 153.32 43.2296 151.844 43.2296C149.509 43.2296 147.887 44.9745 147.887 47.5C147.887 49.9949 149.525 51.6786 151.909 51.6786ZM152.314 49.8878C150.952 49.8878 150.028 48.9235 150.028 47.4694C150.028 46.0459 150.952 45.0357 152.298 45.0357C153.628 45.0357 154.601 45.9541 154.601 47.4694C154.601 48.8163 153.725 49.8878 152.314 49.8878Z" fill="white"/>
  <path d="M159.136 51.4796H161.244V47.6684C161.244 46.2296 162.055 45.2959 163.401 45.2959H164.277V43.398H163.498C162.314 43.398 161.536 44.1173 161.26 44.7449H161.13V43.4286H159.136V51.4796Z" fill="white"/>
  <path d="M169.614 51.6786C171.171 51.6786 171.933 50.7908 172.176 50.4541H172.29V51.4796H174.366V43.4286H172.258V44.4694H172.144C171.852 44.0561 171.025 43.2296 169.549 43.2296C167.214 43.2296 165.592 44.9745 165.592 47.5C165.592 49.9949 167.23 51.6786 169.614 51.6786ZM170.02 49.8878C168.657 49.8878 167.733 48.9235 167.733 47.4694C167.733 46.0459 168.657 45.0357 170.003 45.0357C171.333 45.0357 172.306 45.9541 172.306 47.4694C172.306 48.8163 171.431 49.8878 170.02 49.8878Z" fill="white"/>
  <path d="M176.841 51.4796H178.949V46.8724C178.949 45.8622 179.679 45.0969 180.701 45.0969C181.674 45.0969 182.339 45.7551 182.339 46.7347V51.4796H184.447V46.2143C184.447 44.5 183.279 43.2296 181.366 43.2296C180.149 43.2296 179.306 43.8112 178.965 44.4082H178.852V43.4286H176.841V51.4796Z" fill="white"/>
  <path d="M190.684 51.6786C192.889 51.6786 194.414 50.6071 194.835 48.9694H192.873C192.581 49.6276 191.786 50.0867 190.748 50.0867C189.386 50.0867 188.494 49.2602 188.429 48.0357H194.916V47.4082C194.916 45.1429 193.424 43.2296 190.684 43.2296C188.186 43.2296 186.386 44.9592 186.386 47.5C186.386 49.9031 188.17 51.6786 190.684 51.6786ZM188.462 46.6276C188.591 45.6173 189.5 44.8214 190.684 44.8214C191.916 44.8214 192.808 45.5408 192.905 46.6276H188.462Z" fill="white"/>
  <path d="M200.874 51.6786C203.08 51.6786 204.604 50.6071 205.026 48.9694H203.064C202.772 49.6276 201.977 50.0867 200.939 50.0867C199.577 50.0867 198.685 49.2602 198.62 48.0357H205.107V47.4082C205.107 45.1429 203.615 43.2296 200.874 43.2296C198.377 43.2296 196.577 44.9592 196.577 47.5C196.577 49.9031 198.361 51.6786 200.874 51.6786ZM198.653 46.6276C198.782 45.6173 199.691 44.8214 200.874 44.8214C202.107 44.8214 202.999 45.5408 203.096 46.6276H198.653Z" fill="white"/>
  <path d="M208.119 51.6786C208.832 51.6786 209.351 51.2041 209.351 50.5459C209.351 49.9031 208.832 49.4133 208.119 49.4133C207.438 49.4133 206.886 49.9031 206.886 50.5459C206.886 51.2041 207.438 51.6786 208.119 51.6786Z" fill="white"/>
  </svg>
</template>


<script>
export default {
   name: 'LogoWhite',
}
</script>

export function sortByPublicTransport (a, b) {
  if (a.average_duration_sec_public_transport === b.average_duration_sec_public_transport) {
    return 0
  } else if (a.average_duration_sec_public_transport > b.average_duration_sec_public_transport) {
    return 1
  } else if (a.average_duration_sec_public_transport < b.average_duration_sec_public_transport) {
    return -1
  }
}

export function sortByTransport(method = 'public') {
  let prop = {
    public: 'duration_sec_public_transport',
    privateCar: 'duration_sec_private_car',
    bicycle: 'duration_sec_bicycle'
  }[method]

  return (a, b) => {
    if (a[prop] === b[prop]) {
      return 0
    } else if (a[prop] > b[prop]) {
      return 1
    } else if (a[prop] < b[prop]) {
      return -1
    }
  }
}

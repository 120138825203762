import axios from 'axios'

function resolveBaseURL () {
  var host = window.location.toString()

  if (host.includes('localhost')) {
    return 'http://localhost:3000'
  } else if (host.includes('nooflab')) {
    return 'https://api.nooflab.tools'
  }

  return ''
}

export const HTTP = axios.create({
  baseURL: resolveBaseURL()
})

HTTP.defaults.headers.common.Authorization = "Bearer b1d2df662af5a3d999dd7d7898c4b189bc6d7931b680852e0b7fd9e8b2cff44e"

// // Add a response interceptor
HTTP.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error)
  return Promise.reject(error)
})

<template>
  <v-container>
    <div class="header">
      <h2>
        <a href="https://kaikkitoimitilat.fi">
          <LogoBlue />
        </a>
      </h2>

      <div class="punchline">
        <HeaderGraphic />
      </div>

      <p>
        Tässä on sata osoitetta, joihin on keskimäärin lyhyin matka hakemistasi postinumeroista.
      </p>
    </div>

    <div class="divider"></div>

    <div class="results">
      <h2>
        <v-icon medium>mdi-map-marker</v-icon> Hakutulos
      </h2>

      <div class="loader" v-if="resultsLoading">
        <h2 class="loading">Lataa tuloksia...</h2>
        <Loader />
      </div>

      <div v-if="results">
        <p class="postal-codes">Postinumeroilla: {{ includedPostalCodesTxt }}</p>

        <div v-for="result of results" v-bind:key="result.location.id">
          <v-divider></v-divider>

          <div class="location-card">
            <h3>{{ result.location.name }}</h3>
            <span class="transport">
              <v-icon medium>mdi-bus</v-icon> {{ friendlyTime(result.average_duration_sec_public_transport, 'hm') }}
            </span>
            <span class="transport">
              <v-icon medium>mdi-car</v-icon> {{ friendlyTime(result.average_duration_sec_private_car, 'hm') }}
            </span>
            <span class="transport">
              <v-icon medium>mdi-bicycle</v-icon> {{ friendlyTime(result.average_duration_sec_bicycle, 'hm') }}
            </span>
          </div>

        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
import Loader from '@/components/Loader'
import LogoBlue from '@/components/LogoBlue'
import HeaderGraphic from '@/components/HeaderGraphic'

import { friendlyTime } from '@/utils/time'
import { sortByPublicTransport } from '@/utils/sort'
import * as api from '@/api'

export default {
  name: 'ResultsPage',
  components: { Loader, LogoBlue, HeaderGraphic },
  data: function () {
    return {
      email: '',
      resultsLoading: true
    }
  },
  computed: {
    results () {
      if (this.$store.state.optimalLocation.result) {
        return this.sortResults(this.$store.state.optimalLocation.result.results)
      }

      return null
    },

    includedPostalCodesTxt () {
      if (this.$store.state.optimalLocation.result) {
        var codes = this.$store.state.optimalLocation.result.included_postal_codes

        return codes.join(', ').trim()
      }

      return ""
    }
  },
  methods: {
    sortResults (results) {
      return results.sort(sortByPublicTransport)
    },
    send () {
      api.sendLink(this.$route.params.id, this.email)
    },

    friendlyTime
  },

  mounted () {
    this.$store.dispatch(
      'getOptimalLocations',
      { id: this.$route.params.id, token: this.$route.query.token }
    ).then(() => {
      this.resultsLoading = false
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
  margin-top: 1rem;
  text-align: center;
}

.header{
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: #303563;
  }

  h1 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 40px;
    color: #303563;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  h2 {
    color: #303563;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .punchline {
    margin-top: 3rem;
    text-align: center;
  }
}

.divider{
  background-color: #303563;
  height: 2px;

  margin-top: 24px;
  margin-bottom: 44px;
}

.results {
  h2{
    color: #303563;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.2px;
  }

  .postal-codes {
    margin-top: 1em;
    margin-bottom: 2em;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }

  .location-card{
    padding: 1em 0;

    .transport {
      width: 10rem;
      display: inline-block;

      i {
        margin-right: 0.2rem;
      }
    }
  }
}

// Override default container padding from Vuetify
.container {
  padding: 3rem;
}
</style>
